import React from "react";
import Layout from "../components/layout";
import { useStaticQuery } from "gatsby";
import SEO from "../components/seo";

const PrivacyPolicy = () => {
  const {
    PrivacyPolicy: {
      frontmatter: { title },
      html,
    },
  } = useStaticQuery(graphql`
    query PrivacyPolicy {
      PrivacyPolicy: markdownRemark(
        fileAbsolutePath: { regex: "/privacy-policy/" }
      ) {
        frontmatter {
          title
        }
        html
      }
    }
  `);
  return (
    <Layout>
      <SEO
        seo={{
          title: title,
          description:
            "This privacy policy notice is served by Element Softworks Ltd of Zephyr Court, Ipswich, IP2 8FH, England under the website merlinpanel.com",
          previewURL: "https://merlinpanel.com/images/privacy/preview.png",
        }}
      />
      <div className="max-w-6xl mx-auto mt-20 policy-main px-4">
        <div className="py-16">
          <h2>{title}</h2>

          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
